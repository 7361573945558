import styled from 'styled-components';
const MontserratRegular = 'Montserrat-Regular';
const MontserratSemiBold = 'Montserrat-SemiBold';
const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
import Scurve from '/public/images/WellnessPage/scurve.svg';
const MediumFont = 'Rubik-Regular';

const  ButtonLearnMoree = styled.button`
width: ${(props) => props.tryFree ? '190px' : '190px'};
height: 45px;
background-color: ${(props) => props.tryFree ? '#fff' : 'rgb(246, 180, 121)' };
color: ${(props) => props.tryFree ? '#1573B9' : '#fff' };
display: ${({display}) => display}; 
padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '10px 10px'};
border-radius: 3px;
border: none;
font-size: 18px;
line-height: 24px;
font-family: Rubik-Medium;
font-style: normal;
cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
box-shadow: 4px 6px 5px -1px #0000001c;
&:hover, &:focus, &:active {
  outline: none;
}

@media (max-width: 350px) {
  width: 59%;
  padding: 14px 10px;
}

@media (max-width: 720px) {
  width: 80%;
  width: 320px;
  height: 50px;
}

@media (min-width: 1420px) {
  font-size: 18px;
  padding: 15px 10px;
}
@media (min-width: 1201px) {
  width: 36%;
  width: 190px;
  font-size: 18px;
  padding: 10px 10px;
}
`;

const TabData = styled.div`
  padding: 160px 0px 30px;
  width:100%;
  max-width: 1200px;
  display:flex;
  align-items:center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  .responsiveWhyButton{
    display:none;
    >button{
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      width: 100%;
      margin-left: 0;
    }
  }
  >div:nth-child(2){
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    img {
        width: 80%;
    }
  }
  > div:last-child{
    float: left;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    flex-direction: column;
    img {
      width: 13%;
      margin-left:16px;
      margin-bottom:10px;
    }
    > p:first-child{
        width:100%;
        font-style: normal;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0;
        color:#69C2FF;
        margin-top: 10px;
        padding-left: 19px;
        font-family: ${MontserratSemiBold};
     }
    > p {
        width:100%;
        font-style: normal;
        font-size: 18px;
        line-height: 26px;
        color: #24395a;
        margin-top: 10px;
        padding-left: 19px;
        font-family: ${MontserratRegular};
        @media(max-width: 500px){
          font-family: ${MontserratRegular};
          text-align: justify;
          padding-right: 12px;
          font-weight: 400;
          font-size: 15px;
          font-style: normal;
          line-height: 18px;
          padding-left: 0px;
          padding-right: 0px;
          text-align: left;
          margin-top: 0px;
        }
        @media(max-width: 435px){
          line-height: 18px;
        }
     }
    > h1 {
        font-size: 30px;
        margin-bottom: 25px;
        color: #24395a;
        font-family: Rubik-bold;
        font-style: normal;
        letter-spacing: 0;
        line-height: 36px;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
        text-transform: capitalize;
        margin-top: 0;
        @media(max-width: 500px){
          text-align: left;
          margin: 0;
        }
    } 
  }
  @media(max-width:1000px){
    >div{
      >h1{
    font-size: 24px;
      }
    }
  }
  @media(max-width:850px){
    flex-direction: column-reverse;
    padding: 0px 20px 80px;
    margin: 0;
    >div:nth-child(2){
      margin-top: 50px;
      >img{
        margin: 0;
        width: 100%;
      }
    }
    >div:last-child{
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin-top: 60px;
      >p:first-child{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        font-family: 'Montserrat-Regular';
        line-height: 19px;
        margin-bottom: 26px;
        text-align: left;
        padding-left: 0px;
      }
      >h1{
        padding: 0;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        text-align: left;
      }
      >img{
        margin-left: 0;
        margin-top: 25px;
        margin-bottom: 25px;
        margin-right: auto;
        width: 78px;
      }
      >p:last-child{
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        padding: 0;
      }
      >button{
        display: none;
      }
  }
  .responsiveWhyButton{
    display: block;
    margin: 0 auto;
    width: 94%;
  }
}

@media(max-width:500px){
  >div{
    >h1{
      font-size: 13px;
      line-height: 19px;
    }
    >p{
      font-size: 11px;
      line-height: 16px;
    }
    p:last-child{
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      padding: 0;
      text-align: justify;
    }
  }
  >div:last-child{
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-top: 56px;
  }
`;

const Button2 = styled.button`
width: ${(props) => props.tryFree ? '200px' : '190px'};
margin-top:20px;
  background-color: ${(props) => props.tryFree ? '#fff' : '#69C2FF' };
  color: ${(props) => props.tryFree ? '#1573B9' : '#fff' };
  display: ${({display}) => display}; 
  padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '12px 10px'};
  border-radius: 3px;
  border: none;
  font-size: 16px;
  font-family: Rubik-Medium;
  font-style: normal;
  line-height: 21px;
  margin-left: 19px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  box-shadow: 4px 6px 5px -1px #0000001C;

  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '50px'};
  }
`;

const Section = styled.div`
  margin: 0 auto;
  height: 530px;
  width: 100%;
  max-width: 1200px;
  display: block;
  font-family: ${RegularFont};
  display: ${({intelligenceSection}) => intelligenceSection ? 'block' : 'flex'};
  align-items: flex-start;
  position: relative;
  padding-top: 84px;
  margin-bottom: -70px;
  @media (max-width: 991px) {
    display: block;
  }
  @media (max-width: 1201px) {
    padding-top: 0px;
  }
  @media (max-width: 930px) {
    flex-flow: column;
    height: 1100px;
    padding-top: 30px;
  }
  @media(max-width: 700px){
    margin-bottom: 0;
    height: auto;
  }
  @media(max-width: 2900px){
    img{
      height: 0px;
      width: 0px;
    }
  }
  @media(max-width: 1000px){
    img{
      width: 100%;
      height: auto;
    }
  }
`;

const  IntroTextContainer = styled.div`
  display: inline-block;
  float: left;
  width: 50%;
  letter-spacing: 1.5px;
  padding: 0px 10px 30px 0px;
  h1 {
     margin-bottom: 25px;
     font-family: ${BoldFont};
     letter-spacing: 0;
     margin-top: 0px;
     font-weight: 700;
     color: rgb(36, 57, 90);
     font-size: 42px;
     line-height: 50px;
     font-weight: 600;
     text-align: left;
  }
  p {
    line-height: 22px;
    font-family: ${MontserratRegular};
    letter-spacing: 0;
    font-weight: 500;
    margin: 25px 0 35px 0;
    color: rgb(36, 57, 90);
    font-size: 18px;
    line-height: 31px;
  }
  .wrap {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .divider{
    width: 75px;
    >img{
      width: 100%;
      height: 5px;
    }
  }
  @media (max-width: 1201px) {
    width: 80%;
    padding: 0px 10px 30px 80px; 
    
    h1{
      margin-bottom: 25px;
      font-family: ${BoldFont};
      letter-spacing: 0;
      margin-top: 10px;
      font-weight: 700;
      text-transform: uppercase;
      color: rgb(36, 57, 90);
      font-size: 36px;
      line-height: 50px;
      font-weight: 600;
      text-align: left;
    }
    p {
    line-height: 22px;
    font-family: ${MontserratRegular};
    letter-spacing: 0;
    font-weight: 500;
    margin: 30px 0 30px 0;
    color: rgb(36, 57, 90);
    font-size: 16px;
    line-height: 31px;
    }
    .wrap {
      width: 60%;
    }
  }
  @media (max-width: 930px) {
    width: 100%;
    .wrap {
      width: 45%;
    }
  }
  @media (max-width: 720px) {
    width: 100%;
    text-align: left;
    >div:last-child{
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  @media(max-width: 350px){
    >div:last-child{
      >button{
        width: 80%;
      }
    }
  }
  @media (max-width: 600px) {
      padding-top: 10px;
      height: 400px;
      .wrap {
        width: 100%;
      }
  }
  @media(max-width: 500px){
    padding: 10px;
    width: 100%;
    max-width: 750px;
    min-width: 350px;
    >h1{
      font-size: 36px;
      font-family: 'Rubik-Bold';
      font-style: normal;
      font-weight: bold;
      line-height: 110.5%;
      margin-left: 20px;
      margin-bottom: 30px;
      margin: 0px 20px 25px 20px;
    }
    .divider{
      margin-left: 20px;
    }
    >p{
      font-weight: 500;
      font-size: 15px;
      line-height: 26px;
      margin: 15px;
      text-align: justify;

font-family: Montserrat-Medium;
font-style: normal;
font-weight: 500;
line-height: 22px;
margin-top: 27px;
margin-bottom: 35px;
    }
  }
  @media (max-width: 350px) {
    .wrap {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  @media (min-width: 1420px) {
    h1 {
      max-width: unset;
      letter-spacing: 1px;
      text-align:unset;
      font-family: ${BoldFont};
      letter-spacing: 0;
      font-weight: 700;
      text-transform: uppercase;
      color: rgb(36, 57, 90);
      font-size: 42px;
      line-height: 50px;
      font-weight: 600;
      text-align: left;
    }
    p {
    line-height: 22px;
    font-family: ${MontserratRegular};
    letter-spacing: 0;
    font-weight: 500;
    margin: 25px 0 35px 0;
    color: rgb(36, 57, 90);
    font-size: 18px;
    line-height: 31px;
    }
    .wrap{
      margin-top: 20px;
    }
  }
  @media (min-width: 1500px) {
    padding: 0px 10px 0px 0px;
  }
`;

const PosterImage = styled.div`
  display: inline-block;
  float: left; 
  padding: 0 0px 0 0px;
  width: 60%;
  text-align: center;

  img {
    width: 100%;
    margin-bottom: -35px;
    max-height: 800px;
    min-height: 420px;
    position: relative;
    height: 500px;
    width: auto;
    top: -52.5px;
    float: right;
  }
  
  @media (max-width: 1201px) {
    width: 50%;
    padding: 0px;
    
    img {
      width: 100%;
      margin-bottom: -70px;
      min-height: unset;
      max-height: unset;
      max-height: 800px;
    min-height: 420px;
    position: relative;
    height: 450px;
    width: auto;
    top: 37px;
    left: -70px
  }
  }
  
  @media (max-width: 1000px) {
    img {
      display: none;
    }
  }
  @media (max-width: 930px) {
    width: 100%;
    padding: 70px 30px 0px 30px;
  }
  @media (max-width: 768px) {
    padding: 30px 30px 0px 30px;
    img {
      top: 90px;
      position: relative;
      margin-bottom: -15px;
    }
  }
  @media (max-width: 600px) {
    padding: 30px 30px 0px 30px;
    img {
      top: 25px;
      position: relative;
      margin-bottom: -15px;
    }
  }
  @media (max-width: 500px) {
    img {
      margin-bottom: -50px;
      min-height: 250px;
      height: 200px;
      position: relative;
    margin: 0px;
    top: 202px;
    height: 200px;
    margin-bottom: 200px;
    left: -20px;
    top: 70px;
    }
  }
  @media (min-width:1400px){
    >img{
      max-height: 650px;
    }
  }
  @media(max-width: 350px){
    margin-top: 20px;
  }
`;

const ResourcesWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  padding-bottom: 0px;
  float:left;
  @media(max-width: 800px){
    padding: 0 10px;
  }
`;
const RowWrapper = styled.div`
  width: 100%;
  padding: ${({selected}) => selected ? '25px 20px' : '15px 20px'};
  background-color: ${({selected, bgColor}) => selected ? bgColor : '#fff'};
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 10px;
  .header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  >img{
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
  }
  >div{
  width: calc(100% - 70px);
  font-size: 22px;
  font-family: ${({customColor}) => customColor ? `${BoldFont}`: `${MediumFont}`} ;
  color: #24395A;
  }
  .downArrow{
  width: 20px;
  transform: ${({selected}) => selected && 'rotateX(180deg)'};
  >img{
  width: 14px;
  cursor: pointer;
  }
  }
  }
  .expand{
  padding: 20px 50px 0;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 26px;
  font-family: ${MontserratRegular};;
  font-style: normal;
  color: #3A3A3A;
  cursor: pointer;
  }
  @media(max-width:500px){
  display:none;
  }
`;
const Resources = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top:30px;
  flex-direction:${({flexDirection}) => flexDirection};
  justify-content: space-between;
  margin-bottom: 150px;
  >div:first-child{
    .responsive{
      display:none;
    }
  }
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    >div:first-child{
      display: flex;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 35px;
      flex-direction: row;
      @media (max-width: 500px) {
        padding-top: 41px;
        padding-bottom: 35px;
      }
      .title{
        font-size: 30px;
        color: ${({col})=>col};
        font-family: Rubik-Medium;
        font-style: normal;
        line-height: 58px;
        letter-spacing: 0;
        display:inline;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
        @media (max-width: 700px){
          font-size: 24px;
        }
      } 
      >img{
        width: 60px;
        height: 60px;
        @media (max-width: 700px){
          margin-left: 20px;
        }
      }
    }
    >div{
      width: 100%;
      display: flex;
      flex-direction: column;
      cursor:pointer;
    }
    >div:nth-child(3){
      >div{
        >div:last-child{
          >img{
            width:100%;
          }
        }
      }
    }
  }
  > div:last-child {
    width: 100%;
    align-items:${({align}) => align};
    img {
        float: left;
        width: 500px;
        height: auto;
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    > div {
        width: 100%;
        padding: 0 62px;
        margin-bottom: 20px;
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        img {
           width: 200px;
           display: flex;
           margin: 0 auto;
           float: unset;
        }
    }
  }
  @media (max-width:600px){
    padding:0;
    margin-top: 10px;
    margin-bottom: 150px;
    >div:first-child{
      padding: 0;
      .normal{
        display:none;
      }
      .responsive{
        display:block;
      }
    }
    >div:last-div{
      display:none;
    }
    .image{
      display:none;
    }
    >div:first-child{
      >div:last-child{
        >div{
          >div{
            .responsive-image{
              >img{
                width:100%;
              }
            }
          }
        }
      }
    }
  }
`;

const MainResources = styled.div`
  width:100%;
  position:relative;
  float: left;
  .line{
    position: absolute;
    left:0px;
    >svg{
      fill: ${({color}) => color};
    }
  }
  >h1{
    width: 80%;
    display: block;
    font-size: 36px;
    text-transform: capitalize;
    color: #24395A;
    line-height: 36px;
    font-style: normal;
    font-family: Rubik-Medium;
    margin-bottom: 20px;
  }
  .scurve{
    float: left;
    width: 100%;
    background-image: url(${Scurve});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: translate(-90px, 90px);
    @media(max-width: 1400px){
      transform: translate(-20px,90px);
    }
  }
  @media(max-width: 500px){
    >h1{
      font-weight: 500;
      font-size: 26px;
      width: 85%;
      margin: 0 20 20px 20;
      padding-left: 20px;
      margin-left: 20px;
      text-align: left;
      font-family: 'Rubik-Medium';
      padding-left: 0px;
    }
    .line{
      padding-left: 20px;
    }
  }
`;

const RowResponsiveWrapper = styled.div`
  display: block;
  width: 100%;
  padding: ${({selected}) => selected ? '25px 20px' : '15px 20px'};
  background-color: ${({selected, bgColor}) => selected ? bgColor : '#fff'};
  flex-direction: column;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 15px;
  .header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    >img{
      width: 35px;
      height: 40px;
      margin-right: 10px;
      cursor: pointer;
    }
    >div{
      width: calc(100% - 70px);
      font-size: 24px;
      font-family: ${({customColor}) => customColor ? `${BoldFont}`: `${MediumFont}`};
      color: #24385A;
    }
    .downArrow{
      width: 20px;
      transform: ${({selected}) => selected && 'rotateX(180deg)'};
      >img{
        width: 14px;
        cursor: pointer;
      }
    }
  }
  .expand{
    padding: 20px 30px 0 50px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 26px;
    font-family: ${MontserratRegular};
    cursor: pointer;
    @media(max-width: 700px){
      padding: 20px 0px 0 0px;
    }
    @media(max-width: 435px){
      font-size:15px;
      
    }
  }
  @media(max-width: 500px){
    display: flex;
    .header{
      >div{
      font-size: 22px;
      }
    }
  }
`;


export{ButtonLearnMoree, TabData, Button2,Section, IntroTextContainer, PosterImage,ResourcesWrapper,RowWrapper,MainResources,Resources,RowResponsiveWrapper};
